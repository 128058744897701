import React from "react";


const Services = () => {
  // services details
  const services = [
    {
      name: "Custom Backend & Web Application Development",
      desc: "Designing and building scalable backend systems and responsive web applications tailored to your business needs, ensuring seamless user experience and performance.",
      icon: "fas fa-laptop-code",
    },
    {
      name: "Web Service Performance Optimization",
      desc: "Enhancing web service performance to reduce load times, boost user experience, and increase operational efficiency.",
      icon: "fas fa-tachometer-alt",
    },
    {
      name: "Database Refactoring & Data Migration",
      desc: "Refactoring databases for improved performance and ensuring secure, seamless data migrations with minimal downtime.",
      icon: "fas fa-database",
    },
    {
      name: "Comprehensive Data Security Solutions",
      desc: "Implementing advanced security measures, including data anonymization and encryption, to safeguard sensitive information and ensure regulatory compliance.",
      icon: "fas fa-shield-alt",
    },
    {
      name: "Third-party Application Integrations",
      desc: "Seamlessly integrating with popular platforms like Revolut Merchant, Revolut Pay, TransferWise, Trolley, Adyen, TranserWise, HubSpot, ShuftiPro, and GUS..",
      icon: "fas fa-plug",
    },
    {
      name: "Automated Testing Aligned with Business Requirements",
      desc: "Developing automated tests that ensure robust functionality and alignment with business goals, reducing errors and enhancing product quality.",
      icon: "fas fa-vial",
    },
  ];

  return (
    <section id="services" className="section bg-light">
      <div className="container">
        {/* Heading */}
        <p className=" text-center mb-2 wow fadeInUp">
          <span className="bg-primary text-dark px-2">What I Do?</span>
        </p>
        <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
          How I can help your next project
        </h2>
        {/* Heading end*/}
        <div className="row gy-5 mt-5">
          {services.length > 0 &&
            services.map((service, index) => (
              <div className="col-sm-6 col-lg-4 wow fadeInUp" key={index}>
                <div className="featured-box text-center px-md-4">
                  <div className="featured-box-icon text-primary text-13">
                    {" "}
                    <i className={service.icon} />
                  </div>
                  <h3 className="text-6 fw-600 mb-3">{service.name}</h3>
                  <p className="text-muted mb-0">{service.desc} </p>
                </div>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};

export default Services;
